import {
    ClockConfig,
    CustomHomepageGrid,
    HeaderWorldClock,
    HomePageCompanyLogo,
    HomePageRandomJoke,
    HomePageStarredEntities,
    HomePageToolkit,
    HomePageTopVisited,
    HomePageRecentlyVisited,
    WelcomeTitle,
  } from '@backstage/plugin-home';
  import { Content, Header, Page } from '@backstage/core-components';
  import { HomePageSearchBar } from '@backstage/plugin-search';
  import React from 'react';
  import HomeIcon from '@material-ui/icons/Home';
  import { HomePagePagerDutyCard } from '@backstage/plugin-pagerduty';
  import CodeIcon from '@mui/icons-material/Code';
  import InsightsIcon from '@mui/icons-material/Insights';
  import BugReportIcon from '@mui/icons-material/BugReport';
  import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
  import AnnouncementIcon from '@mui/icons-material/Announcement';
  import StorageIcon from '@mui/icons-material/Storage';
  import AltRouteIcon from '@mui/icons-material/AltRoute';
  import AssignmentIcon from '@mui/icons-material/Assignment';

  const clockConfigs: ClockConfig[] = [
    {
      label: 'Maassluis',
      timeZone: 'Europe/Amsterdam',
    },
    {
      label: 'Istanbul',
      timeZone: 'Asia/Istanbul',
    },
  ];
  
  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  
  const defaultConfig = [
    {
      component: 'CompanyLogo',
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      movable: false,
      resizable: false,
      deletable: false,
    },
    {
      component: 'HomePageSearchBar',
      x: 0,
      y: 1,
      width: 12,
      height: 2,
    },
    {
      component: 'HomePageStarredEntities',
      x: 0,
      y: 3,
      width: 6,
      height: 5,
    },
    {
      component: 'HomePageRecentlyVisited',
      x: 6,
      y: 3,
      width: 6,
      height: 5,
    },
    {
      component: 'HomePageToolkit',
      x: 0,
      y: 9,
      width: 12,
      height: 4,
    },
  ];

  
  export const homePage = (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Content>
        <CustomHomepageGrid config={defaultConfig}>
          <HomePageSearchBar />
          <HomePageRandomJoke />
          <HomePagePagerDutyCard name="Rota" />
          <HomePageStarredEntities />
          <HomePageCompanyLogo />
          <WelcomeTitle />
          <HomePageToolkit
            tools={[
              {
                url: 'https://backstage.horizon.lely.cloud',
                label: 'Backstage Homepage',
                icon: <HomeIcon />,
              },
              {
                url: 'https://gitlab.lelyonline.com/',
                label: 'Lely Gitlab',
                icon: <CodeIcon />,
              },
              {
                url: 'https://app.datadoghq.eu/account/login/id/1cd157f0-ff95-11ec-90ce-da7ad0900005',
                label: 'Datadog',
                icon: <InsightsIcon />,
              },
              {
                url: 'https://sonarqube.horizon.lely.cloud/',
                label: 'SonarQube',
                icon: <BugReportIcon />,
              },
              {
                url: 'https://app.split.io/',
                label: 'Split.io',
                icon: <AltRouteIcon />,
              },
              {
                url: 'https://onelely.eu.pagerduty.com/incidents',
                label: 'PagerDuty',
                icon: <CrisisAlertIcon />,
              },
              {
                url: 'https://status.horizon.lely.cloud/',
                label: 'Horizon Statuspage',
                icon: <AnnouncementIcon />,
              },
              {
                url: 'https://argo-cd.ldf.lely.cloud',
                label: 'ArgoCD',
                icon: <StorageIcon />,
              },
              {
                url: 'https://targetprocess.lelyonline.com/',
                label: 'Targetprocess',
                icon: <AssignmentIcon />,
              }
            ]}
          />
          <HomePageTopVisited />
          <HomePageRecentlyVisited />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );