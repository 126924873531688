import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} width="100%" viewBox="0 0 130 84" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path className={classes.svg} d="M64.993.032C29.098.032 0 18.83 0 42.016 0 65.2 29.098 84 64.993 84c35.893 0 64.99-18.8 64.99-41.984 0-23.186-29.097-41.984-64.99-41.984" fill="#FFFFFE"></path>
                                <path className={classes.svg} d="M64.993.032C29.098.032 0 18.83 0 42.016 0 65.2 29.098 84 64.993 84c35.893 0 64.99-18.8 64.99-41.984 0-23.186-29.097-41.984-64.99-41.984" fill="#FFFFFE"></path>
                                <path className={classes.svg} d="M4.724 42.055c0-20.79 27.026-37.647 60.366-37.647 33.337 0 60.362 16.856 60.362 37.647 0 20.79-27.025 37.646-60.362 37.646-33.34 0-60.366-16.855-60.366-37.645z" fill="#C30A14"></path>
                                <path className={classes.svg} fill="#FFFFFE" d="M95.564 64.8L87.9 61.385v-17l-8.636-17.79 5.292-2.293 5.867 12.093 3.85-16.273 7.075-3.03-5.78 25.63-.004 22.08M82.97 59.18l-14.916-6.656.007-21.123 7.04-3.03v21.265l7.865 3.11.005 6.435M61.448 36.652l-5.066-1.622.01 4.503 5.014.884-.002 3.378-5.013.875-.01 4.054 5.068-1.91v5.337l-10.722 4.88.005-30.247L61.45 31.41v5.242M46.91 58.765l-16.94 7.69V17.83l7.778 3.375.01 34.526 9.153-3.433v6.468"></path>
                            </g>
                        </svg>
  );
};

export default LogoFull;
