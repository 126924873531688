import {
    createTheme,
    genPageTheme,
    lightTheme,
  } from '@backstage/theme';
  
  var lelyShapes = {
    solid: `url("data:image/svg+xml,%3csvg width='1400' height='400'%3e%3cpath d='M0 0h1400v400H0z'/%3e%3c/svg%3e")`,
  };

  export const lely = createTheme({
    palette: {
      ...lightTheme.palette,
      primary: {
        main: '#C30A14',
      },
      secondary: {
        main: '#73130E',
      },
      error: {
        main: '#FF2315',
      },
      warning: {
        main: '#FF9800',
      },
      info: {
        main: '#FFEB3B',
      },
      success: {
        main: '#18AC20',
      },
      background: {
        default: '#F7F3F2',
        paper: '#FFFFFF',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#C30A14',
        indicator: '#73130E',
        color: '#FFFFFF',
        selectedColor: '#FFF',
        navItem: {
          hoverBackground: '#73130E',
        },
        submenu: {
          background: '#A01F18',
        },
      },
    },
    defaultPageTheme: 'home',
    fontFamily: 'Ubuntu',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#C30A14', '#C30A14'], shape: lelyShapes.solid }),
      documentation: genPageTheme({
        colors: ['#C30A14', '#C30A14'],
        shape: lelyShapes.solid,
      }),
      tool: genPageTheme({ colors: ['#C30A14', '#73130E'], shape: lelyShapes.solid }),
      service: genPageTheme({
        colors: ['#C30A14', '#73130E'],
        shape: lelyShapes.solid,
      }),
      website: genPageTheme({
        colors: ['#C30A14', '#73130E'],
        shape: lelyShapes.solid,
      }),
      library: genPageTheme({
        colors: ['#C30A14', '#73130E'],
        shape: lelyShapes.solid,
      }),
      other: genPageTheme({ colors: ['#C30A14', '#73130E'], shape: lelyShapes.solid }),
      app: genPageTheme({ colors: ['#C30A14', '#73130E'], shape: lelyShapes.solid }),
      apis: genPageTheme({ colors: ['#C30A14', '#73130E'], shape: lelyShapes.solid }),
    },
  });